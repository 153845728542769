<template>
  <td style="text-align: center;">
    <v-tooltip bottom v-if="dataItem.modelName">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          ><v-icon>mdi-clock</v-icon></v-btn
        >
      </template>

      <span>處理時間</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.modelName">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="openForge()"
          :disabled="dataItem.status == 5 ? false : true"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </template>

      <span>檢視內容</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.modelName">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          @click="routeToGroup()"
          ><v-icon>mdi-folder-open</v-icon></v-btn
        >
      </template>

      <span>前往列表</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {
      downloadType: '',
    };
  },
  methods: {
    openForge() {
      this.$emit('openForge', { dataItem: this.dataItem });
    },
    routeToGroup() {
      this.$emit('routeToGroup', { dataItem: this.dataItem });
    },
    stateCheck() {
      if (this.dataItem.state == '已完成' || this.dataItem.state == '已處理') {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style></style>
