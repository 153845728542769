<template>
  <v-card id="chartCard" flat style="height: 100%">
    <canvas id="myChart" :width="width" :height="height"></canvas
    ><v-overlay
      absolute
      :value="overlay"
      color="white"
      opacity="0.4"
      :dark="false"
    >
      <span class="pa-4" style="font-weight: 700; font-size:25px"
        >暫無數據
        <!-- No data available -->
      </span>
    </v-overlay>
  </v-card>
</template>
<script>
import Chart from 'chart.js';
//  class="small pt-1"
export default {
  props: {
    company: {
      type: Object,
    },
    chartLabels: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
    chartAllData: {
      type: Array,
    },
    overlay: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      myChart: {},
      height: '420',
      width: '800',
      bluebackgroundColor: [
        'rgba(0,106,216,0.45)',
        'rgba(0,196,215,0.55)',
        'rgba(5,156,199,0.45)',
        'rgba(10,54,198,0.45)',
        'rgba(56,0,215,0.45)',
        'rgba(106,0,215,0.45)',
      ],
      blueborderColor: [
        'rgba(0,106,216,1)',
        'rgba(0,196,215,1)',
        'rgba(5,156,199,1)',
        'rgba(10,54,198,1)',
        'rgba(56,0,215,1)',
        'rgba(106,0,215,1)',
      ],
      greenbackgroundColor: [
        'rgba(76,175,80,0.45)',
        'rgba(60,179,113,0.6)',
        'rgba(46,139,87,0.5)',
        'rgba(0,100,0,0.5)',
        'rgba(107,142,35,0.5)',
        'rgba(85,107,47,0.5)',
      ],
      greenborderColor: [
        'rgba(76,175,80,1)',
        'rgba(60,179,113,1)',
        'rgba(46,139,87,1)',
        'rgba(0,100,0,1)',
        'rgba(107,142,35,1)',
        'rgba(85,107,47,1)',
      ],
      graybackgroundColor: [
        'rgba(126,126,126,0.45)',
        'rgba(169,169,169,0.45)',
        'rgba(211,211,211,0.45)',
        'rgba(100,100,100,0.45)',
        'rgba(112,128,144,0.45)',
        'rgba(127,156,155,0.45)',
      ],
      grayborderColor: [
        'rgba(126,126,126,1)',
        'rgba(169,169,169,1)',
        'rgba(211,211,211,1)',
        'rgba(100,100,100,1)',
        'rgba(112,128,144,1)',
        'rgba(127,156,155,1)',
      ],
    };
  },
  // created() {
  //   window.addEventListener('resize', this.getSize);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getSize);
  // },
  watch: {
    chartLabels: {
      handler() {
        this.showChart();
      },
      deep: true,
    },
    chartData: {
      handler() {
        if (this.company.id !== null) {
          this.showChart();
        }
      },
      deep: true,
    },
    chartAllData: {
      handler() {
        if (this.company.id == null) {
          this.showAllChart();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getSize();
  },
  methods: {
    showAllChart() {
      var ctx = document.getElementById('myChart');
      var datasetValue = [];
      var c1 = 0;
      var c2 = 0;
      var c3 = 0;
      for (let i = 0; i < this.chartAllData.length; i++) {
        if (this.chartAllData[i].label.includes('轉檔')) {
          var backgroundColor = this.bluebackgroundColor[c1];
          var borderColor = this.blueborderColor[c1];
          c1 = c1 + 1;
        } else if (this.chartAllData[i].label.includes('加值')) {
          backgroundColor = this.greenbackgroundColor[c2];
          borderColor = this.greenborderColor[c2];
          c2 = c2 + 1;
        } else {
          backgroundColor = this.graybackgroundColor[c3];
          borderColor = this.grayborderColor[c3];
          c3 = c3 + 1;
        }
        datasetValue[i] = {
          label: this.chartAllData[i].label,
          data: this.chartAllData[i].data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        };
      }
      if (window.myCharts != undefined) window.myCharts.destroy();
      window.myCharts = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartLabels.labelText,
          datasets: datasetValue,
        },
        options: {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.chartLabels.xAxesStr,
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMin: -20,
                  suggestedMax: 20,
                },
                stacked: true,
              },
            ],
          },
        },
      });
    },
    showChart() {
      var ctx = document.getElementById('myChart');
      if (window.myCharts != undefined) window.myCharts.destroy();
      window.myCharts = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartLabels.labelText,
          datasets: [
            {
              label: '使用量',
              data: this.chartData.usageData,
              backgroundColor: 'rgba(0,106,216,0.45)',
              borderColor: 'rgba(0,106,216,1)',
            },
            {
              label: '加值',
              data: this.chartData.addData,
              backgroundColor: 'rgba(76,175,80,0.45)',
              borderColor: 'rgba(76,175,80,1)',
            },
            {
              label: '退款',
              data: this.chartData.refundData,
              backgroundColor: 'rgba(126,126,126,0.45)',
              borderColor: 'rgba(126,126,126,1)',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.chartLabels.xAxesStr,
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMin: -20,
                  suggestedMax: 20,
                },
                stacked: true,
              },
            ],
          },
        },
      });
    },
    getSize(height, width) {
      if (height !== undefined && width !== undefined) {
        this.height = String(height);
        if (height < 450) {
          this.width = String(height * 1.7);
        } else {
          this.width = String(width);
        }
      }
      this.showChart();
    },
  },
};
</script>
<style></style>
