<template>
  <v-card flat id="gridCard" style="height: calc(100vh-224px)">
    <localization-provider :language="$i18n.locale">
      <intl-provider :locale="$i18n.locale"
        ><grid
          id="detailsGrid"
          ref="detailsGrid"
          :data-items="gridResults.gridData"
          :skip="gridResults.dataState.skip"
          :take="gridResults.dataState.take"
          :filter="gridResults.dataState.filter"
          :sort="gridResults.dataState.sort"
          :columns="columns"
          scrollable
          sortable
          reorderable
          column-menu
          pageable
          @datastatechange="dataStateChange"
          @routeToGroup="routeToGroup"
          @openForge="openForge"
          :style="isFullScreen ? 'height: 95.7vh' : 'height: 52.5vh'"
        >
          <grid-no-records
            :style="{ minHeight: gridResults.loading ? '256px' : '128px' }"
          >
            <div v-if="gridResults.loading" class="k-loading-mask">
              <span class="k-loading-text"></span>
              <div class="k-loading-image" />
              <div class="k-loading-color" />
            </div>
            <div v-else>{{ $t('form.no_records') }}</div>
          </grid-no-records>
        </grid>
      </intl-provider>
    </localization-provider>
    <v-dialog v-model="forgeVuerDialog"
      ><v-btn
        class="mt-8"
        color="primary"
        style="z-index: 50"
        fab
        dark
        absolute
        top
        right
        @click="forgeVuerDialog = false"
      >
        <v-icon>mdi-close</v-icon> </v-btn
      ><ForgeVuerDialog :model="model" /></v-dialog
    ><LoadingDialog v-model="loadingDialog"
  /></v-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import ApiConfig from '../../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import AlignRightCell from '../../GridCell/AlignRightCell';
import AlignCenterCell from '../../GridCell/AlignCenterCell';
import DetailTypeCell from '../../GridCell/DetailTypeCell';
import CheckTimeCell from '../../GridCell/CheckTimeCell';
import DetailContentCell from '../../GridCell/DetailContentCell';
import DateFormat from '../../../plugins/DateFormat';
import ForgeVuerDialog from '../../Dialog/ForgeVuerDialog';
import LoadingDialog from '../../Dialog/LoadingDialog.vue';
export default {
  components: {
    ForgeVuerDialog,
    LoadingDialog,
  },
  props: {
    company: {
      type: Object,
    },
    isFullScreen: {
      type: Boolean,
    },
    companyId: {
      type: String,
    },
    timeSelected: {
      type: Boolean,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    saveGrid: {
      type: Object,
    },
  },
  data() {
    return {
      size: '',
      model: {},
      myStartDate: null,
      myEndDate: null,
      forgeVuerDialog: false,
      loadingDialog: false,
      gridResults: {
        loading: false,
        list: [],
        total: 0,
        updatedData: [],
        gridData: {
          data: [],
          total: 0,
        },
        dataState: {
          page: 1,
          take: 20,
          skip: 0,
          filter: null,
          group: '',
        },
        pageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [10, 20, 50],
          previousNext: true,
        },
      },
      myColumns: [
        { field: 'id', title: '編號', width: '150px', cell: AlignCenterCell },
        {
          field: 'date',
          title: '時間',
          width: '170px',
          // format: '{0:yyyy-MM-dd HH:mm}',
          cell: AlignCenterCell,
        },
        {
          field: 'type',
          title: '類別',
          width: '100px',
          minResizableWidth: 100,
          cell: DetailTypeCell,
        },
        { field: 'content', title: '內容', cell: DetailContentCell },
        {
          field: 'amount',
          title: '點數',
          width: '100px',
          cell: AlignRightCell,
        },
        {
          field: 'balance',
          title: '餘額',
          width: '100px',
          cell: AlignRightCell,
        },
      ],
      detailList: [
        {
          // amount: 500
          // billId: "6c0fd1c7-0d91-42b8-9d90-514a9ea1311b"
          // companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0"
          // date: "2021-03-11T00:00:00+00:00"
          // expireDate: "2022-03-11T00:00:00+00:00"
          // id: "20210311_026"
          // modelId: null
          // note: null
          // processingId: null
        },
      ],
      filter: null,
    };
  },
  watch: {
    endDate: {
      handler(val) {
        if (val !== null) {
          this.myStartDate = this.startDate;
          this.myEndDate = val;
          this.setTimeFilter();
        }
      },
    },
    // startDate: {
    //   handler(val) {
    //     if (val !== null) {
    //       this.myStartDate = val;
    //       this.myEndDate = this.endDate;
    //     }
    //   },
    // },
    filter: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    company: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  computed: {
    columns() {
      return [
        ...this.myColumns,
        //其他動作欄位：檢視表單及參數、下載
        {
          width: '168px',
          minResizableWidth: 115,
          cell: CheckTimeCell,
        },
      ];
    },
  },
  mounted() {
    if (this.timeSelected && this.startDate !== null && this.endDate !== null) {
      this.myStartDate = this.startDate;
      this.myEndDate = this.endDate;
      this.setTimeFilter();
    } else if (this.saveGrid !== null) {
      this.myStartDate = this.saveGrid.startDate;
      this.myEndDate = this.saveGrid.endDate;
      this.setTimeFilter();
    } else {
      this.loadData();
    }
  },
  methods: {
    setTimeFilter() {
      if (this.myStartDate !== null && this.myEndDate !== null) {
        var startTime = new Date(this.myStartDate).toISOString();
        var endTime = new Date(this.myEndDate).toISOString();

        this.filter = {
          logic: 'and',
          filters: [
            {
              filters: [
                {
                  field: 'date',
                  operator: 'gte',
                  value: startTime,
                },
                {
                  field: 'date',
                  operator: 'lte',
                  value: endTime,
                },
              ],
              logic: 'and',
            },
          ],
        };

        this.gridResults.dataState.filter = this.filter;
      }
    },
    async loadData() {
      this.gridResults.loading = true;
      var companyId = this.company.id;
      var dataState = this.gridResults.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);
      if (companyId !== null) {
        var res = await this.$API.api.pc.companyProcessingUsage.getUsedDetail(
          companyId,
          urlencoded,
          ApiConfig.getJsonConfig
        );
      } else {
        res = await this.$API.api.pc.systemProcessingUsage.get(
          urlencoded,
          ApiConfig.getJsonConfig
        );
      }

      this.detailList = cloneDeep(res.data);
      this.convertTime();
      this.loadList();
    },
    convertTime() {
      for (let i = 0; i < this.detailList.data.length; i++) {
        this.detailList.data[i].date = DateFormat.dateForVuetify(
          this.detailList.data[i].date,
          true,
          true
        ).substring(0, 16);
        if (this.detailList.data[i].expireDate !== null) {
          this.detailList.data[i].expireDate = DateFormat.dateForVuetify(
            this.detailList.data[i].expireDate,
            true,
            true
          ).substring(0, 10);
        }
      }
    },
    loadList() {
      this.$data.gridResults.total = this.detailList.total;
      this.$data.gridResults.list = this.detailList.data;
      this.processData();
    },
    processData() {
      this.gridResults.gridData.data = this.gridResults.list;
      this.gridResults.gridData.total = this.gridResults.total;
      this.gridResults.loading = false;
    },
    dataStateChange(event) {
      var dataState = event.data;
      this.gridResults.dataState.take = dataState.take;
      this.gridResults.dataState.skip = dataState.skip;
      this.gridResults.dataState.sort = dataState.sort;
      this.gridResults.dataState.filter = dataState.filter;
      this.loadData();
    },
    /**跳轉到模型列表*/
    routeToGroup(dataItem) {
      var model = dataItem.dataItem;
      var groupId = 'null';
      if (model.groupId !== null) {
        groupId = model.groupId;
      }
      const { href } = this.$router.resolve({
        name: 'ManagePage',
        params: { companyId: this.companyId },
        query: {
          group: groupId,
          item: model.itemId,
          version: model.versionId,
        },
      });
      window.open(href, '_blank');
    },
    /**檢視內容 打開forgeVuer */
    openForge(model) {
      this.loadingDialog = true;
      var modelId = model.dataItem.modelId;
      return new Promise((resolve) => {
        this.$API.api.pc.productModel
          .get(modelId, ApiConfig.getJsonConfig)
          .then((res) => {
            // console.log('res', res);
            this.model = res.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loadingDialog = false;
            this.forgeVuerDialog = true;
            resolve();
          });
      });
    },
    saveGridState() {
      if (this.myStartDate !== null && this.myEndDate !== null) {
        this.$emit('saveGridState', this.myStartDate, this.myEndDate);
      }
    },
  },
};
</script>
