<template>
  <v-card class="pa-4"
    ><v-card-text class="pa-0"
      >合約期間：{{ contractStart }}~{{ contractEnd }}</v-card-text
    ></v-card
  >
</template>

<script>
export default {
  data() {
    return {
      contractStart: '2021-04-08',
      contractEnd: '2022-05-08',
    };
  },
};
</script>
