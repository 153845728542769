<template>
  <v-card flat style=" height: 84.7vh;">
    <v-card-title style=" height: 68px;">
      <v-icon class="pr-3"> mdi-chart-bar</v-icon>模型處理額度管理
      <v-spacer />

      <v-card v-if="timeSearch" flat color="transparent"
        ><TimeSelect @timeSelect="timeSelect"
      /></v-card>
      <!-- 按鈕：顯示選擇時間區間 或 查詢+清除 -->
      <v-btn
        v-if="!timeSearch"
        color="primary"
        class="pl-5"
        @click="timeSearch = true"
        ><v-icon left>mdi-calendar-clock</v-icon>選擇時間區間</v-btn
      >
      <v-btn v-if="timeSearch" icon small @click="timeSearchClear()"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on }">
          <v-btn outlined color="#6B6B6B" v-on="on" class="ml-3">
            <span>{{ company.name }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in companies"
            :key="item.id"
            @click="changeCompany(item)"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text
      class="ma-0 pa-0 pt-1"
      style="
        height:calc(100% - 67px); background-color:#F3F3F3;
      "
    >
      <v-card v-if="!timeSelected" flat color="transparent"
        ><SummaryCard :balance="balance" :totalUsed="totalUsed"/></v-card
      ><v-card v-if="timeSelected" flat color="transparent"
        ><TimeRangeCard
          :startDate="startDate"
          :endDate="endDate"
          :balance="balance"
          :totalUsed="totalUsed"
          :rangeUsed="rangeUsed"/></v-card
      ><v-card
        flat
        color="transparent"
        style="
        height:calc(100% - 190px);
      "
        ><BottomCard
          ref="bottomCard"
          :company="company"
          :startDate="startDate"
          :endDate="endDate"
          :companyId="companyId"
          :timeSelected="timeSelected"
          @clearDate="clearDate"/></v-card></v-card-text
  ></v-card>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig';
import TimeSelect from './TimeSelect';
import SummaryCard from './SummaryCard';
import TimeRangeCard from './TimeRangeCard';
import BottomCard from './BottomCard/Main';
export default {
  components: { TimeSelect, SummaryCard, TimeRangeCard, BottomCard },
  props: {
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      balance: null,
      totalUsed: null,
      rangeUsed: null,
      timeSearch: false,
      timeSelected: false,
      startDate: null,
      endDate: null,
      company: { name: '全部', id: null },
      companies: [],
    };
  },
  mounted() {
    this.loadCompanies();
    this.loadBalance();
    this.loadUsed();
  },
  methods: {
    /**讀取資料 */
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    async loadUsed() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getUsed(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.totalUsed = -res.data;
    },
    async loadRangeUsed() {
      var start = this.startDate + 'T00:00:00+08:00';
      var end = this.endDate + 'T00:00:00+08:00';
      var res = await this.$API.api.pc.companyProcessingUsage.getUsed(
        this.companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      // console.log('res', res);
      this.rangeUsed = -res.data;
    },
    timeSelect(startDate, endDate) {
      this.timeSelected = true;
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadRangeUsed();
    },
    timeSearchClear() {
      this.$refs.bottomCard.saveGridClear();
      this.timeSearch = false;
      this.timeSelected = false;
      this.rangeUsed = null;
      this.startDate = null;
      this.endDate = null;
    },
    clearDate() {
      this.startDate = null;
      this.endDate = null;
    },
    /**讀取PC模組的所有公司 */
    async loadCompanies() {
      var moduleValue = 'Module.ProductConfigurator';
      var res = await this.$API.api.main.moduleCompany.get(
        moduleValue,
        ApiConfig.getJsonConfig
      );
      var data = res.data;
      var companies = [{ name: '全部', id: null }];
      for (let i = 0; i < data.length; i++) {
        companies.push(data[i]);
      }
      this.companies = companies;
    },
    /**切換公司 */
    changeCompany(company) {
      this.company = company;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: none;
}
</style>
