<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row
      ><v-radio-group v-model="option" row class="pa-0 ma-0 pt-2" hide-details>
        <v-radio
          v-for="o in options"
          :key="o.id"
          :label="o.name"
          :value="o.value"
        ></v-radio>
      </v-radio-group>
      <v-card flat width="230px" class="pt-1 mr-1">
        <v-menu
          ref="end"
          v-model="end"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          :disabled="option !== 'custom' ? true : false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              class="pa-0 ma-0"
              hide-details
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              :disabled="option !== 'custom' ? true : false"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range no-title></v-date-picker>
        </v-menu> </v-card
      ><v-btn color="primary" class="mr-5 ml-3" @click="timeSelect()"
        >查詢</v-btn
      ></v-row
    ></v-container
  >
</template>

<script>
export default {
  data() {
    return {
      option: null,
      options: [
        { id: 1, name: '近一週', value: 'week' },
        { id: 2, name: '近一個月', value: 'month' },
        { id: 3, name: '近一年', value: 'year' },
        { id: 4, name: '自訂', value: 'custom' },
      ],
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      dates: [null, null],
      start: false,
      end: false,
    };
  },
  watch: {
    option: {
      handler(val) {
        if (val == 'week') {
          this.weekSelect();
        } else if (val == 'month') {
          this.monthSelect();
        } else if (val == 'year') {
          this.yearSelect();
        }
      },
      deep: true,
    },
    dates: {
      handler(val) {
        if (val[1] !== undefined) {
          this.startDate = new Date(val[0]).toISOString().substr(0, 10);
          this.endDate = new Date(val[1]).toISOString().substr(0, 10);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.setStartEnd();
    this.dates = [this.startDate, this.endDate];
  },
  computed: {
    dateRangeText() {
      //前後日期排序
      if (this.dates[1] !== undefined) {
        this.sortTime();
      }
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    sortTime() {
      return this.dates.sort((a, b) => (new Date(a) < new Date(b) ? -1 : 1));
    },
    setStartEnd() {
      var startDate = new Date();
      startDate.setDate(1);
      this.startDate = startDate.toISOString().substr(0, 10);
    },
    weekSelect() {
      var startDate = new Date();
      startDate = startDate.setDate(startDate.getDate() - 7);
      this.startDate = new Date(startDate).toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
      this.dates = [this.startDate, this.endDate];
    },
    monthSelect() {
      var startDate = new Date();
      startDate = startDate.setMonth(startDate.getMonth() - 1);
      this.startDate = new Date(startDate).toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
      this.dates = [this.startDate, this.endDate];
    },
    yearSelect() {
      var startDate = new Date();
      startDate = startDate.setYear(startDate.getFullYear() - 1);
      this.startDate = new Date(startDate).toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
      this.dates = [this.startDate, this.endDate];
    },
    timeSelect() {
      if (this.option !== null) {
        this.$emit('timeSelect', this.startDate, this.endDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-text-field--disabled {
  font-weight: 800 !important;
  font-size: 30 !important;
  opacity: 1 !important;
}
</style>
