<template>
  <v-card class="ma-3" style="height: 156px">
    <v-container fluid style="height: 156px"
      ><v-row align="end" style="height: 120px">
        <v-card flat min-width="180px"
          ><v-card-text class="stringText"
            >期間使用量
            <span style="font-size: 14px">
              &#173;&#173; ( {{ myStartDate }}~{{ myEndDate }} )</span
            ></v-card-text
          >
          <v-skeleton-loader
            v-if="loading"
            width="110"
            height="50"
            type="card"
            class="mt-3 ml-6 mb-3"
            style="margin-bottom: 2px"
          />
          <v-card-title
            v-if="!loading"
            class="numberText"
            style="color: #006ad8;"
            >{{ rangeUsed }}</v-card-title
          > </v-card
        ><v-col class="pb-0 pr-4" align="end"
          ><v-card flat
            ><v-card-text class="pa-0 ma-0"
              >剩餘額度：<span class="noteText">{{
                balance
              }}</span></v-card-text
            ><v-card-text class="pa-0 ma-0 pt-3"
              >總使用量：<span class="noteText">{{
                totalUsed
              }}</span></v-card-text
            ></v-card
          ></v-col
        ></v-row
      ></v-container
    >
  </v-card>
</template>

<script>
export default {
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    balance: {
      type: Number,
    },
    totalUsed: {
      type: Number,
    },
    rangeUsed: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      myStartDate: null,
      myEndDate: null,
    };
  },
  watch: {
    rangeUsed: {
      handler(val) {
        if (val !== null) {
          this.loading = false;
        }
      },
      deep: true,
    },
    endDate: {
      handler(val) {
        if (val !== null) {
          this.myStartDate = this.startDate;
          this.myEndDate = val;
        }
      },
    },
    startDate: {
      handler(val) {
        if (val !== null) {
          this.myStartDate = val;
          this.myEndDate = this.endDate;
        }
      },
    },
  },
  mounted() {
    if (this.rangeUsed !== null) {
      this.loading = false;
    }
    this.myStartDate = this.startDate;
    this.myEndDate = this.endDate;
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.numberText {
  font-size: 60px;
  letter-spacing: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 12px;
  margin-bottom: 12px;
}
.stringText {
  font-size: 16px;
  margin-left: 22px;
  padding: 6px;
}
.noteText {
  font-size: 30px;
  letter-spacing: 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 5px;
}
</style>
