<template>
  <v-card class="ma-3" style="height: 156px" :loading="loading">
    <v-container fluid
      ><v-row class="mt-0 mb-0"
        ><v-card flat min-width="200px" class="mr-2"
          ><v-card-text class="stringText">剩餘額度</v-card-text>
          <v-skeleton-loader
            v-if="loading"
            width="110"
            height="50"
            type="card"
            class="mt-3 ml-6 mb-3"
            style="margin-bottom: 2px"/>
          <v-card-title
            v-if="!loading"
            class="numberText"
            style="color: #848484;"
            >{{ balance }}</v-card-title
          >
          <v-card-text class="pa-0 pl-6"
            >有效期限：{{ exp }}
            <v-menu open-on-hover offset-y right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small style="margin-top: -3px" color="#959595"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <v-card><ExpDetailCard /></v-card>
            </v-menu> </v-card-text
        ></v-card>

        <v-divider vertical />
        <v-card flat min-width="180px" class="mr-2"
          ><v-card-text class="stringText">總處理量</v-card-text>
          <v-skeleton-loader
            v-if="loading"
            width="110"
            height="50"
            type="card"
            class="mt-3 ml-6 mb-3"
            style="margin-bottom: 2px"
          />
          <v-card-title
            v-if="!loading"
            class="numberText"
            style="color: #006ad8;"
            >{{ totalUsed }}</v-card-title
          >
        </v-card>

        <v-divider vertical />
        <v-card flat min-width="150px" class="mr-2"
          ><v-card-text class="stringText">成本 </v-card-text
          ><v-card-title class="numberText" style="color: #848484;">{{
            cost
          }}</v-card-title></v-card
        >
        <v-card flat
          ><v-tooltip top>
            <template v-slot:activator="{ on }"
              ><v-btn
                icon
                small
                v-on="on"
                @click="moreDetail()"
                class="mt-1 mr-1"
                ><v-icon>mdi-dots-vertical</v-icon></v-btn
              ></template
            >

            <span>檢視更多</span>
          </v-tooltip></v-card
        >

        <v-divider vertical />
        <v-card flat min-width="180px"
          ><v-card-text class="stringText">收益</v-card-text
          ><v-card-title class="numberText" style="color: #006ad8;">{{
            earnings
          }}</v-card-title></v-card
        >

        <v-col class="pb-0 pr-4 pt-0" align="end"
          ><span style="color:#5E5E5E"
            >最後更新時間：{{ lastUpdate }}</span
          ></v-col
        ></v-row
      ></v-container
    >
  </v-card>
</template>

<script>
import ExpDetailCard from './ExpDetailCard';
export default {
  components: {
    ExpDetailCard,
  },
  props: {
    balance: {
      type: Number,
    },
    totalUsed: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      startDate: '2021-03-11',
      endDate: '2022-03-11',
      lastUpdate:
        new Date().toISOString().substr(0, 10) +
        ' ' +
        new Date().toTimeString().substr(0, 5),
      exp: '2022-05-08',
      cost: 50,
      earnings: 3640,
    };
  },
  watch: {
    balance: {
      handler(val) {
        if (val !== null) {
          this.loading = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.balance !== null) {
      this.loading = false;
    }
  },
  methods: {
    moreDetail() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.numberText {
  font-size: 60px;
  letter-spacing: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 12px;
  margin-bottom: 12px;
}
.stringText {
  font-size: 16px;
  margin-left: 22px;
  padding: 6px;
}
</style>
