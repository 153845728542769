<template>
  <v-card flat style="height: 100%; align-items: center;"
    ><v-container fluid class="pa-0"
      ><v-row class="ma-0 pa-0" justify="end" align="center">
        <ChartToolbar
          ref="toolbar"
          :company="company"
          :type="type"
          :overlay="overlay"
          :saveChart="saveChart"
          @typeChange="typeChange"
          @saveChartClear="saveChartClear"
        />
      </v-row>
      <v-row justify="center" align="center"
        ><v-btn
          style="margin-right: 30px; margin-left: 30px"
          fab
          depressed
          :disabled="overlay"
          @click="getLast()"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <!-- <v-card v-if="loading" flat class="mt-5"><v-skeleton-loader  width="700"
            height="200" type="image" />
            <v-skeleton-loader width="700"
            height="100" type="image" /></v-card> -->
        <Chart
          ref="chart"
          :company="company"
          :chartLabels="chartLabels"
          :chartData="chartData"
          :chartAllData="chartAllData"
          :overlay="overlay"
        />
        <v-btn
          style="margin-left: 30px;margin-right: 30px "
          fab
          depressed
          :disabled="checkTime.checkDate == null ? true : false"
          @click="getNext()"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row></v-container
    ></v-card
  >
</template>

<script>
import { cloneDeep } from 'lodash';
import ApiConfig from '../../../plugins/ApiConfig';
// import { toDataSourceRequestString } from "@progress/kendo-data-query";
import Chart from './Chart';
import ChartToolbar from './ChartToolbar';
// import DateFormat from "../../../plugins/DateFormat";
import moment from 'moment/src/moment';
export default {
  components: {
    Chart,
    ChartToolbar,
  },
  props: {
    company: {
      type: Object,
    },
    companyId: {
      type: String,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    timeSelected: {
      type: Boolean,
    },
    saveChart: {
      type: Object,
    },
  },
  data() {
    return {
      type: 'day',
      labelMonth: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      chartLabels: {
        labelText: [],
        xAxesStr: '',
      },
      chartData: {
        usageData: [],
        addData: [],
        refundData: [],
      },
      chartAllData: [],
      latestTime: {
        latestYear: null,
        latestMonth: null,
        latestDate: null,
      },
      checkTime: {
        checkYear: null,
        checkMonth: null,
        checkDate: null,
      },
      dataState: {
        page: 0,
      },
      allData: [],
      days: null,
      overlay: false,
      loading: false,
    };
  },
  watch: {
    startDate: {
      handler(val) {
        if (val !== null && !this.loading) {
          this.countRange();
        }
      },
      deep: true,
    },
    endDate: {
      handler(val) {
        if (val !== null && !this.loading) {
          this.countRange();
        }
      },
      deep: true,
    },
    company: {
      handler() {
        this.getLatest();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.saveChart !== null) {
      this.getLatest();
      this.type = this.saveChart.type;
      this.chartLabels = this.saveChart.chartLabels;
      this.chartData = this.saveChart.chartData;
      this.checkTime = this.saveChart.checkTime;
    }
  },
  methods: {
    saveChartState() {
      this.$emit(
        'saveChartState',
        this.type,
        this.chartLabels,
        this.chartData,
        this.checkTime
      );
    },
    saveChartClear() {
      this.$emit('saveChartClear');
    },
    getSize(height, width) {
      this.$refs.chart.getSize(height, width);
    },
    typeChange(type) {
      this.type = type;
      this.checkTime.checkDate = null;
      switch (type) {
        case 'day':
          this.getLatest();
          break;
        case 'month':
          this.chartLabels.labelText = cloneDeep(this.labelMonth);
          if (this.checkTime.checkDate == null) {
            this.chartLabels.xAxesStr = String(this.latestTime.latestYear);
          }
          this.getMonthData();
          break;
      }
    },
    /** type == "day" */
    /**設定最新日期 */
    getLatest() {
      var latestDate = new Date();
      var latestTime = {
        latestDate: latestDate,
        latestYear: latestDate.getFullYear(),
        latestMonth: latestDate.getMonth() + 1,
      };
      this.latestTime = latestTime;
      // this.latestDate = new Date(today);
      // this.latestYear = this.latestDate.getFullYear();
      // this.latestMonth = this.latestDate.getMonth() + 1;
      this.days = this.getDays(latestDate);

      if (this.saveChart == null) {
        this.getData();
      }
    },
    /**預設設定，以日為呈現方式，先抓取最新月份的資料 */
    async getData() {
      if (this.checkTime.checkDate == null) {
        if (this.latestTime.latestMonth < 10) {
          var month = '0' + this.latestTime.latestMonth;
        } else {
          month = this.latestTime.latestMonth;
        }
        var start =
          this.latestTime.latestYear + '-' + month + '-01T00:00:00+08:00';
        var end = new Date().toISOString().substr(0, 10) + 'T00:00:00+08:00';
      } else {
        if (this.checkTime.checkMonth < 10) {
          month = '0' + this.checkTime.checkMonth;
        } else {
          month = this.checkTime.checkMonth;
        }

        start = this.checkTime.checkYear + '-' + month + '-01T00:00:00+08:00';
        end =
          this.checkTime.checkYear +
          '-' +
          month +
          '-' +
          this.days +
          'T00:00:00+08:00';
      }
      if (this.company.id == null) {
        var res = await this.$API.api.pc.systemProcessingUsage.getByDay(
          start,
          end,
          ApiConfig.getJsonConfig
        );
      } else {
        res = await this.$API.api.pc.companyProcessingUsage.getDetailByDay(
          this.company.id,
          start,
          end,
          ApiConfig.getJsonConfig
        );
      }

      this.allData = res.data;
      this.loading = false;
      this.getLabels();
    },
    /**設定labels */
    getLabels() {
      if (this.checkTime.checkDate == null) {
        this.chartLabels.xAxesStr =
          this.latestTime.latestYear + '/' + this.latestTime.latestMonth;
      } else {
        this.chartLabels.xAxesStr =
          this.checkTime.checkYear + '/' + this.checkTime.checkMonth;
      }

      var labels = [];
      for (let i = 0; i < this.days; i++) {
        labels.push(i + 1);
      }

      this.chartLabels.labelText = labels;
      if (this.company.id !== null) {
        this.getChartData();
      } else {
        this.getAllChartData();
      }
    },
    /**設定chartData*/
    getChartData() {
      //加值
      if (this.allData[0]) {
        this.chartData.addData = this.setData(this.allData[0]);
      }
      //使用
      if (this.allData[1] !== undefined) {
        this.chartData.usageData = this.setData(this.allData[1]);
      } else {
        this.chartData.usageData = [0];
      }
      //退款
      if (this.allData[2] !== undefined) {
        this.chartData.refundData = this.setData(this.allData[2]);
      } else {
        this.chartData.refundData = [0];
      }
    },
    /**設定顯示全部公司時的chartData*/
    getAllChartData() {
      var chartAllData = [];
      for (let i = 0; i < this.allData.length; i++) {
        var t = {
          label: this.allData[i].name,
          data: this.setData(this.allData[i]),
        };
        chartAllData.push(t);
      }
      this.chartAllData = chartAllData;
    },
    setData(d) {
      var data = d.datum;
      var chartData = [];
      for (let i = 0; i < this.days; i++) {
        chartData.push(0);
      }

      for (let i = 0; i < data.length; i++) {
        var date = new Date(data[i].startDateTime);

        if (this.checkTime.checkDate == null) {
          if (
            date.getFullYear() == this.latestTime.latestYear &&
            date.getMonth() + 1 == this.latestTime.latestMonth
          ) {
            var loc = date.getDate() - 1;
            var amount = data[i].amount;
            chartData.splice(loc, 1, amount);
          }
        } else {
          if (
            date.getFullYear() == this.checkTime.checkYear &&
            date.getMonth() + 1 == this.checkTime.checkMonth
          ) {
            var loc2 = date.getDate() - 1;
            var amount2 = data[i].amount;
            chartData.splice(loc2, 1, amount2);
          }
        }
      }
      return chartData;
    },
    /** type == "month" */
    async getMonthData() {
      this.chartData.usageData = [];
      this.chartData.addData = [];
      this.chartData.refundData = [];

      var year = null;
      if (this.checkTime.checkDate == null) {
        year = this.latestTime.latestYear;
      } else {
        year = this.checkTime.checkYear;
      }
      var start = year + '-01-01T00:00:00+08:00';
      var end = year + '-12-31T23:59:59+08:00';
      if (this.company.id !== null) {
        var res = await this.$API.api.pc.companyProcessingUsage.getDetailByMonth(
          this.company.id,
          start,
          end,
          ApiConfig.getJsonConfig
        );
      } else {
        res = await this.$API.api.pc.systemProcessingUsage.getByMonth(
          start,
          end,
          ApiConfig.getJsonConfig
        );
      }

      this.monthData = res.data;

      if (this.company.id !== null) {
        //加值
        if (this.monthData[0]) {
          this.chartData.addData = this.setMonthData(this.monthData[0]);
        }
        //使用
        if (this.monthData[1]) {
          this.chartData.usageData = this.setMonthData(this.monthData[1]);
        }
        //退款
        if (this.monthData[2]) {
          this.chartData.refundData = this.setMonthData(this.monthData[2]);
        }
        this.loading = false;
      } else {
        this.getAllMonthData();
      }
    },
    getAllMonthData() {
      var chartAllData = [];
      for (let i = 0; i < this.monthData.length; i++) {
        var t = {
          label: this.monthData[i].name,
          data: this.setMonthData(this.monthData[i]),
        };
        chartAllData.push(t);
      }
      this.chartAllData = chartAllData;
    },
    setMonthData(d) {
      var data = d.datum;
      var chartData = [];
      for (let i = 0; i < 12; i++) {
        chartData.push(0);
      }

      for (let i = 0; i < data.length; i++) {
        var date = new Date(data[i].startDateTime);

        if (this.checkTime.checkDate == null) {
          if (date.getFullYear() == this.latestTime.latestYear) {
            var loc = date.getMonth();
            var amount = data[i].amount;
            chartData.splice(loc, 1, amount);
          }
        } else {
          if (date.getFullYear() == this.checkTime.checkYear) {
            var loc2 = date.getMonth();
            var amount2 = data[i].amount;
            chartData.splice(loc2, 1, amount2);
          }
        }
      }
      return chartData;
    },
    getLast() {
      if (this.type == 'day') {
        this.getLastMonth();
      } else {
        this.getLastYear();
      }
      this.$emit('clearDate');
    },
    getNext() {
      if (this.type == 'day') {
        this.getNextMonth();
      } else {
        this.getNextYear();
      }
      this.$emit('clearDate');
    },
    getLastMonth() {
      if (this.checkTime.checkDate == null) {
        this.checkTime.checkDate = cloneDeep(this.latestTime.latestDate);
      }

      this.checkTime.checkDate.setMonth(this.checkTime.checkDate.getMonth());
      this.checkTime.checkDate.setDate(-5);
      this.days = this.getDays(this.checkTime.checkDate);

      this.checkTime.checkYear = this.checkTime.checkDate.getFullYear();
      this.checkTime.checkMonth = this.checkTime.checkDate.getMonth() + 1;

      this.getData();
    },
    getNextMonth() {
      if (this.checkTime.checkDate !== null) {
        this.checkTime.checkDate.setMonth(
          this.checkTime.checkDate.getMonth() + 1
        );
        this.days = this.getDays(this.checkTime.checkDate);

        this.checkTime.checkYear = this.checkTime.checkDate.getFullYear();
        this.checkTime.checkMonth = this.checkTime.checkDate.getMonth() + 1;
      }
      this.getData();
      if (
        this.checkTime.checkYear == this.latestTime.latestYear &&
        this.checkTime.checkMonth == this.latestTime.latestMonth
      ) {
        this.checkTime.checkDate = null;
      }
    },
    getLastYear() {
      if (this.checkTime.checkDate == null) {
        this.checkTime.checkDate = cloneDeep(this.latestTime.latestDate);
      }
      this.checkTime.checkDate.setYear(
        this.checkTime.checkDate.getFullYear() - 1
      );
      this.checkTime.checkYear = this.checkTime.checkDate.getFullYear();
      this.chartLabels.xAxesStr = String(this.checkTime.checkYear);
      this.getMonthData();
    },
    getNextYear() {
      if (this.checkTime.checkDate !== null) {
        this.checkTime.checkDate.setYear(
          this.checkTime.checkDate.getFullYear() + 1
        );
        this.checkTime.checkYear = this.checkTime.checkDate.getFullYear();
      }
      this.chartLabels.xAxesStr = String(this.checkTime.checkYear);
      if (this.checkTime.checkYear == this.latestTime.latestYear) {
        this.checkTime.checkDate = null;
      }
      this.getMonthData();
    },
    //計算查詢的時間區間，超過365天則以月為單位顯示圖表
    countRange() {
      this.loading = true;
      var start = new Date(this.startDate);
      var end = new Date(this.endDate);
      var dateDiff = Math.round((end - start) / (1000 * 60 * 60 * 24));

      this.checkTime.checkDate = new Date(this.endDate) - 1000 * 60 * 60 * 8;
      this.checkTime.checkDate = new Date(this.checkTime.checkDate);
      this.checkTime.checkYear = this.checkTime.checkDate.getFullYear();
      this.checkTime.checkMonth = this.checkTime.checkDate.getMonth() + 1;
      this.days = this.getDays(this.checkTime.checkDate);

      //如果dateDiff >= 365，type == month
      if (dateDiff >= 365) {
        this.type = 'month';
        var chartLabels = {
          labelText: cloneDeep(this.labelMonth),
          xAxesStr: String(this.checkTime.checkYear),
        };
        this.chartLabels = chartLabels;
        // this.getMonthData();
      } else {
        this.type = 'day';
        this.getData();
      }
    },
    getDays(date) {
      var timeForMoment = new Date(date).toISOString().substr(0, 7);
      return moment(timeForMoment, 'YYYY-MM').daysInMonth();
    },
  },
};
</script>
