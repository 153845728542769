<template>
  <v-card flat>
    <v-menu bottom right offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          outlined
          color="#6B6B6B"
          v-on="on"
          class="mt-3 mr-3"
          :disabled="disabled"
          @click="saveChartClear()"
        >
          <span>{{ typeToLabel[mytype] }}</span>
          <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="mytype = 'day'">
          <v-list-item-title>日</v-list-item-title>
        </v-list-item>
        <v-list-item @click="mytype = 'month'">
          <v-list-item-title>月</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item @click="type = 'year'">
          <v-list-item-title>年</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu></v-card
  >
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
    },
    overlay: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    saveChart: {
      type: Object,
    },
  },
  data() {
    return {
      companyChange: false,
      disabled: false,
      mytype: null,
      typeToLabel: {
        day: '日',
        month: '月',
        // year: '年',
      },
    };
  },
  watch: {
    mytype: {
      handler(val) {
        if (this.saveChart == null && !this.companyChange) {
          this.$emit('typeChange', val);
        }
        this.companyChange = false;
      },
      deep: true,
    },
    type: {
      handler(val) {
        this.mytype = val;
      },
      deep: true,
    },
    overlay: {
      handler(val) {
        this.disabled = val;
      },
      deep: true,
    },
    company: {
      handler() {
        this.companyChange = true;
        this.mytype = 'day';
      },
      deep: true,
    },
  },
  mounted() {
    this.mytype = this.type;
  },
  methods: {
    saveChartClear() {
      this.$emit('saveChartClear');
    },
  },
};
</script>
