<template>
  <v-card outlined style="height:85vh;"
    ><UploadQuota :companyId="companyId"
  /></v-card>
</template>

<script>
// import { mapState } from 'vuex';
import UploadQuota from '../../components/UploadQuotaManager/Main';
export default {
  components: {
    UploadQuota,
  },
  data() {
    return {
      companyId: this.$route.params.companyId,
    };
  },
  //   computed: {
  //   ...mapState('auth', ['company']),
  //   ...mapState('company', {
  //     companyId: (state) => state.company.id,
  //   }),
  // },
  // mounted(){
  //   console.log("company", this.company)
  //   for(let i = 0; i < this.company.functions.length; i++) {
  //    console.log(this.company.functions[i].name)
  //   }
  // }
};
</script>
